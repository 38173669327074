import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect:"/charts"
  },
  {
    path: '/login',
    name: 'login',
    component: function () {
      return import('../views/login.vue')
    }
  },
  {
    path: '/memberManage',
    name: 'memberManage',
    component: function () {
      return import('../views/memberManage.vue')
    }
  },
   {
    path: '/memberDetail',
    name: 'memberDetail',
    component: function () {
      return import('../views/memberDetail.vue')
    }
  },
  {
    path: '/integral',
    name: 'integral',
    component: function () {
      return import('../views/integral.vue')
    }
  },
  {
    path: '/charts',
    name: 'charts',
    component: function () {
      return import('../views/charts.vue')
    }
  },
  {
    path: '/memberEdit',
    name: 'memberEdit',
    component: function () {
      return import('../views/memberEdit.vue')
    }
  },
  {
    path: '/memberView',
    name: 'memberView',
    component: function () {
      return import('../views/memberView.vue')
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  console.log(to.path);

if(sessionStorage.getItem("Token")!="" && sessionStorage.getItem("Token")!=null &&sessionStorage.getItem("Token")!=undefined){
      next()
    }else if(to.path=='/login'){
      next()
    }else {
      if(to.path=='/charts' || to.path=='/integral'|| to.path=='/memberDetail'){
        next()
      }else{
        next("/login")
      }
      }
})
export default router
