import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import { createApp } from './App.vue';
import Vant from 'vant';
import 'vant/lib/index.css';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import { postRequest, getRequest, excelRequest, riskRequest, Requestsh,formPost,mobilePost } from '@/api/public';

Vue.prototype.$http = {
  post: postRequest,
  get: getRequest,
  export: excelRequest,
  baseQuery: riskRequest,
  Requestmum: Requestsh,
  formPost:formPost,
  mobilePost:mobilePost
};

Vue.prototype.photoUrl="http://yctyclub.qianmo-tech.com/imgFile/"

Vue.use(Vant);
Vue.use(ElementUI);
// const app = createApp();
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
