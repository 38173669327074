import request from '@/utils/request';
import quest from "@/utils/quest"
// 公共post请求接口方法
export const postRequest = (url, data) => {
    return request({
        url: url,
        method: 'post',
        data: data,
        headers: {
            'Content-Type': 'application/json;',
        },
    });
};

// 公共post请求接口方法
export const riskRequest = (url, data) => {
    return query({
        url: url,
        method: 'post',
        data: data,
        headers: {
            'Content-Type': 'application/json;',
        },
    });
};

// 公共post请求接口方法
export const excelRequest = (url, data) => {
    return request({
        url: url,
        method: 'post',
        data: data,
        // headers: {
        //     'Content-Type': 'application/octet-stream;',
        // },
        responseType: "blob"
    });
};
// 公共post请求接口方法
export const Requestsh = (url, data) => {
    return request({
        url: url,
        method: 'post',
        data: data,
        config: {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }

    });
};

// 公共get请求接口方法
export const getRequest = (url, data) => {
    return request({
        url: url,
        method: 'get',
        params: data,
        headers: {
            'Content-Type': 'application/json;',
        }
    });
};

export const formPost = (url, data) => {
    return request({
        url: url,
        method: 'post',
        data: data,
        headers: {
            'Content-Type': 'multipart/form-data;'
        }
    });
};

export const mobilePost = (url, data) => {
    return quest({
        url: url,
        method: 'post',
        data: data,
        headers: {
            'Content-Type': 'application/json;',
        }
    });
};