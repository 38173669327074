import axios from "axios";
const service = axios.create({
    //小贷系统
    baseURL: 'https://yctyclub.qianmo-tech.com/club/',
    //baseURL: '/front/0401020203002/credit',
    timeout: 20000, // request timeout,
});

service.defaults.withCredentials = true;

service.interceptors.request.use(
    (config) => {
        return config;
    },
    (error) => {
        Promise.reject(error);
    }
);
// response interceptor
service.interceptors.response.use(
    (response) => {
        return response.data;
    },
    (error) => {
        return Promise.reject(error);
    }
);


export default service;
